export default {
	init: (app, Listing) => {
        const filters = $('.filters');
        const container = $('.listing');
        const listing = new Listing(filters, container);

        listing.on('loading', (e) => {
            container.addClass('listing--loading');
            
            var scrollTop = filters.parents('.section').offset().top - $('.header').outerHeight();

            if(scrollTop < $(window).scrollTop()) {
                $('html, body').animate({
                    scrollTop: scrollTop
                }, {
                    duration: 500,
                });
            }
        });

        listing.on('loaded', (e) => {
            $(window).trigger('scroll');
            container.removeClass('listing--loading');
        });

        listing.on('success', (e) => {
            container[0].querySelectorAll('[data-src], [data-bg]').forEach((item) => app.lazyLoader.observe(item))
        });
	}
}