export default class Listing {
    constructor(form, container) {
        this.form = form;
        this.id = this.form.attr('id');
        this.url = this.form.attr('action');
        this.ajaxPath = this.form.data('ajax');
        this.ajaxMethod = this.form.attr('method');
        this.container = container;
        this.xhr;

        this.form.on('submit', this.submit.bind(this));
        this.form.on('change', 'input, select', this.submit.bind(this));
        $(document).on('change', '[form="' + this.id + '"]', this.submit.bind(this));
        this.container.on('click', '.pagination-link, .pagination-arrow', this.paginate.bind(this));
    }

    on(event, callback) {
        this.container.on(event, callback);
    }

    updateURL(url = '') {
        if(url == '') {
            var baseUrl = this.url;
            var data = this.form.serializeArray();
            var query = '';

            for(var i = 0; i < data.length; i++) {
                if(data[i].value != '') {
                    query += query == '' ? '?' : '&';
                    query += data[i].name + '=' + data[i].value;
                }
            }

            url = baseUrl + query;
        }

        history.replaceState({}, document.title, url);
    }

    send(data) {
        this.xhr = $.ajax({
            url: this.ajaxPath,
            method: this.ajaxMethod,
            data: data,
            dataType: 'json',
            beforeSend: () => {
                this.container.trigger('loading');
            },
            complete: () => {
                this.container.trigger('loaded');
            },
            success: response => {
                this.container.html(response);
                this.container.trigger('success', response);
            },
            error: (response, status, error) => {
                this.container.trigger('error', response);
            }
        });
    }

    submit(e) {
        if(e) e.preventDefault();
        if(this.xhr) this.xhr.abort();

        this.updateURL();
        this.send(this.form.serialize());
    }

    paginate(e) {
        if(e) e.preventDefault();
        if(this.xhr) this.xhr.abort();

        var link = $(e.target).closest('a');
        var page = link.data('page');
        var url = link.attr('href');
        var data = url.split('?')[1];

        this.updateURL(url);
        this.send(data + '&page=' + page);
    }
}