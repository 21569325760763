export default {
	init: (app, Overlay, Plyr) => {

		/*	
		|
		| Menu remove when scroll up
		|----------------------------
		*/
		var position = $(window).scrollTop();
		$(window).scroll(function () {
			var scroll = $(window).scrollTop();
			var scrollDifference = scroll - position;

			if (scrollDifference > 30) {
				jQuery('body').addClass('menuUp');
			} else if (scrollDifference < -30) {
				jQuery('body').removeClass('menuUp');
			}

			position = scroll;
		});

		/*
		|
		| Menu Anchor
		|-----------
		*/
		jQuery(document).ready(function () {
			if ($(".anchors")[0]) {

				function anchorScroll() {
					var height = $(window).scrollTop();
					if ($(".notifications").length) {
						if (height + 10 >= 540) {
							jQuery("body").find(".anchor-fixed").css({ top: '0px' });
							$(".anchors").addClass("anchor-fixed");
						} else {
							jQuery("body").find(".anchor-fixed").css({ top: '0px' });
							$(".anchors").removeClass("anchor-fixed");
						}
					} else {
						if (height + 10 >= 540) {
							jQuery("body").find(".anchor-fixed").css({ top: '0px' });
							$(".anchors").addClass("anchor-fixed");
						} else {
							jQuery("body").find(".anchor-fixed").css({ top: '0px' });
							$(".anchors").removeClass("anchor-fixed");
						}
					}
				}

				jQuery(document).ready(function () {
					anchorScroll();
				});
				jQuery(document).scroll(function () {
					anchorScroll();
				});
			}
		});

		/*
		|
		| Anchors
		|-----------------
		*/
		var sections = $('.ancre');
		var nav = $('.anchors');
		var nav_height = nav.outerHeight();
		var offsettop = 300;

		$(window).on('scroll', function () {
			var cur_pos = $(this).scrollTop();

			sections.each(function () {
				var top = $(this).offset().top - nav_height - offsettop,
					bottom = top + $(this).outerHeight();

				if (cur_pos >= top - 5) {
					nav.find('.link-anchor').removeClass('active');
					sections.removeClass('active');

					$(this).addClass('active');
					nav.find('.link-anchor[href="#' + $(this).attr('id') + '"]').addClass('active');
				}
			});
		});

		nav.find('.link-anchor').on('click', function () {
			var $el = $(this)
				, id = $el.attr('href');

			$('html, body').animate({
				scrollTop: $(id).offset().top - nav_height - offsettop
			}, 500);

			return false;
		});

		/*	
		|
		| Plyr
		|-----------------
		*/
		const $customPlyrVideo = jQuery('.custom-plyr-video');
		$.each($customPlyrVideo, function () {
			const $video = $(this);
			const $videoPoster = $video.find('.item-poster');
			const $videoIcon = $video.find('.item-play-icon');
			const videoId = $video.data('id');
			const type = $video.data('type');
			let player = new Plyr(`#${videoId}`);
			$(`#${videoId}`).addClass('custom-plyr-video');

			player.on("play", (event) => {
				let delayInMilliseconds = 500;
				setTimeout(function () {
					jQuery('.item-poster').css('opacity', '0');
				}, delayInMilliseconds);
			});
		});


		/*-------- Lazy loading --------*/
		document.querySelectorAll('[data-src], [data-bg]').forEach((item) => app.lazyLoader.observe(item))

		/*-------- Modals --------*/
		document.querySelectorAll('.modal').forEach((item) => {
			app.overlays[item.id] = new Overlay(item)
		})
	}
}