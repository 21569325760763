/*
|
| Importing Libs
|------------------
*/
import gsap from 'gsap';
import SplitText from '@lib/gsap-pro/SplitText';
import ScrollTo from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollTo);

/*
|
| Importing Components
|-----------------------
*/
import Kira from '@components/kira';
import Menu from '@components/menu';
import Overlay from '@components/overlays';
import Plyr from '@components/plyr.min.js';

/*
|
| Importing Utils
|-------------------
*/
import * as app from '@utils/global';
import Router from '@utils/router';
import Listing from '@utils/listing';

/*
|
| Importing App files
|----------------------
*/
import main from '@pages/main';
import listing from '@pages/listing';
import animations from '@pages/animations';
import contact from '@pages/contact';

/*
|
| Routing
|----------
*/
const routes = new Router([
    {
        file: animations,
        dependencies: [app, gsap, SplitText, Menu, Kira]
    },
    {
        file: main,
        dependencies: [app, Overlay, Plyr],
    },
    {
        file: listing,
        dependencies: [app, Listing],
        resolve: '.listing'
    },
    {
        file: contact,
        resolve: '#page-contact'
    }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);
