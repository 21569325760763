export default {
	init: (app, gsap, SplitText, Menu, Kira) => {
        /*
		|
		| Loader
		|---------
        */
        const loader = document.querySelector('.page-loader')
        
        if(sessionStorage.getItem('loaded_once') === null) {
            sessionStorage.setItem('loaded_once', 'loaded_once')
        }

        if(loader.classList.contains('active')) {
            const loaderTl = gsap.timeline({ paused: true })
            
            loaderTl.to(loader.querySelector('.item-loadbar-inner'), {
                scaleX: 1,
                duration: .4,
                ease: 'Power0.easeNone'
            }, 'start')
            
            loaderTl.to(loader.querySelector('.item-content'), {
                autoAlpha: 0,
                duration: .8,
                ease: 'Power1.easeOut'
            }, '-=0')

            loaderTl.call(() => { app.dispachEvent(document.body, 'dom:ready') }, null)

            loaderTl.to(loader, {
                autoAlpha: 0,
                duration: .8,
                ease: 'Power1.easeOut'
            }, '-=0')

            addEventListener('load', () => loaderTl.play())
        } else {
            addEventListener('load', () => app.dispachEvent(document.body, 'dom:ready'))
        }

        /*
		|
		| Menu
		|-------
        */
        const menuWrapper = document.getElementById('mobile-menu')
        const menuButton = document.querySelector('#header .btn-menu')
        const menuBars = menuButton.querySelectorAll('.item-burger > span')
        const menuStaggerItems = menuWrapper.querySelectorAll('[data-stagger-item]')
        const menu = new Menu(menuWrapper, menuButton, { reverseTimeScale: 2 })

        menu.menuTimeline
            .to(menuBars[1], {
                autoAlpha: 0,
                duration: .3
            }, 'start')
			.to(menuBars[0], {
                x: 0,
                y: 8,
                rotation: 45,
                duration: .5,
                ease: 'Power1.easeOut'
            }, 'start')
            .to(menuBars[2], {
                x: 0,
                y: -8,
                rotation: -45,
                duration: .5,
                ease: 'Power1.easeOut'
            }, 'start')
			.to(menuWrapper, {
                autoAlpha: 1,
                duration: .5,
                ease: 'Power1.easeOut'
            }, 'start+=0.3')
            .from(menuStaggerItems, {
                x: 20,
                autoAlpha: 0,
                duration: .6,
                stagger: .1,
                ease: 'Power1.easeOut'
            }, '+=0')

        menu.init()

        /*
		|
		| SplitText
		|------------
        */
        document.querySelectorAll('[data-split-text]').forEach((item) => {
            item.split = new SplitText(item, {
                type: item.dataset.splitText ? item.dataset.splitText : 'words, chars',
                linesClass: 'split-line',
                wordsClass: 'split-word',
                charsClass: 'split-char'
            })

            item.classList.add('split-ready')
        })
        
        /*
        |
		| Kira
		|-----------
        */
        const kira = new Kira({
            loadEvent: [document.body, 'dom:ready'],
            scrollTrigger: {
                markers: false,
                //scroller: scrollContainerSelector,
            },
            tweenParams: {
                start: '-=0.6'
            }
        })

        /*
        | fade-in-up
        |-----------
        */
        kira.add('fade-in-up', (item, timeline, start, duration) => {
            timeline.fromTo(item, {
                y: 50
            }, {
                y: 0,
                autoAlpha: 1,
                duration: duration|| 1.3,
                ease: 'easeSmooth'
            }, start)
        })

        /*
        | split-line
        |------------
        */
        kira.add('split-lines', (item, timeline, start, duration, delay) => {
            timeline.from(item.split.words ? item.split.words : item.split.chars, {
                yPercent: 101,
                delay: delay || 0,
                duration: duration || 1.35,
                ease: 'easeSmooth'
            }, start)
        })

        /*
        | split-chars
        |-----------------
        */
        kira.add('split-chars', (item, timeline, start, duration, delay) => {
            const stagger = item.hasAttribute('data-kira-stagger') ? parseFloat(item.dataset.kiraStagger) : .03

            timeline.from(item.split.chars, {
                opacity: 0,
                yPercent: 30,
                delay: delay || 0,
                duration: duration || .2,
                stagger: stagger,
                ease: 'Sine.ease0ut'
            }, start)
        })

        /*
        | counter
        | data-decimal-separator to chose decimal separator (defaults to ",")
        | data-thousands-separator to chose thousands separator (defaults to " ")
        |-----------------
        */
        kira.add('counter', (item, timeline, start, duration, delay) => {
            const decimalSeparator = item.hasAttribute('data-decimal-separator') ? item.dataset.decimalSeparator : ','
            const thousandsSeparator = item.hasAttribute('data-thousands-separator') ? item.dataset.thousandsSeparator : ' '

            let goal = item.textContent.replaceAll(',', '.').replaceAll(' ', '')
            let counter = { val: 0 }

            let decimals = goal.split('.')[1]
            let toFixed = decimals ? decimals.length : false

            goal = toFixed ? parseFloat(goal) : parseInt(goal)

            const inner = document.createElement('span')
            inner.classList.add('counter-value')

            item.setAttribute('data-counter-goal', item.textContent)
            item.textContent = ''
            item.appendChild(inner)

            timeline.to(counter, {
                val: goal,
                duration: duration || 1.3,
                delay: delay || 0,
                ease: 'easeSmooth',
                onUpdate: () => {
                    let val = 0

                    if(toFixed) {
                        let [number, decimals] = counter.val.toFixed(toFixed).toString().split('.', 2)
                        number = number.replaceAll(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator)
                        val = [number, decimals].join(decimalSeparator)
                    } else {
                        val = Math.round(counter.val).toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator)
                    }

                    inner.textContent = val
                }
            }, start)
        })

        kira.init()
	}
}